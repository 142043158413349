var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c("br"),
        _c("c-upload", {
          attrs: {
            attachInfo: _vm.attachInfo,
            label: "LBL0002635",
            editable: _vm.editable && !_vm.disabled,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }